<template>
  <section>
    <section class="headline-style d-none d-lg-block">
      <section class="headline jd-text-dark py-2">
        <b-container>
          <p class="jd-text-29 m-0 jd-text-20__mobile jd-font-regular jd-home-text text-letter text-wrap-pre text-uppercase text-center">
            {{ service.title }}
          </p>
        </b-container>
      </section>
    </section>

    <b-container class="mt-4 text-center">
      <section v-html="service.sub_title"></section>
    </b-container>

    <b-container v-if="CART_ITEMS.length" id="cart">
      <section class="d-flex justify-content-center">
        <section class="shopping--cart-box">
          <h2 class="title jd-font-bold jd-text-45 jd-text-30__mobile">Shopping cart</h2>
          <div class="d-flex justify-content-end items">
            <table>
              <tr>
                <th class="jd-font-medium text-right">Item</th>
                <th class="jd-font-medium">Qt.</th>
                <th class="jd-font-medium">Price</th>
                <th></th>
                <th></th>
              </tr>

              <tr v-for="(CART_ITEM, key) in CART_ITEMS" :key="key">
                <td class="jd-font-regular text-right">{{ CART_ITEM.name }}</td>
                <td class="jd-font-regular text-center">
                  <span v-if="editId !== CART_ITEM.id">{{ CART_ITEM.count }}</span>
                  <input type="number" v-else v-model="editQt" class="input-edit"/>
                </td>
                <td class="jd-font-regular">${{ CART_ITEM.price }}</td>
                <td>
                  <i class="fa fa-trash-alt c-pointer" @click="REMOVE_CART_ITEM(CART_ITEM.id)"></i>
                </td>
                <td>
                  <i class="fa fa-pencil-alt c-pointer" @click="editCartQt(CART_ITEM.id, CART_ITEM.count)"
                     v-if="editId !== CART_ITEM.id"></i>
                  <i class="fa fa-check c-pointer" @click="editQtSave(CART_ITEM.id)" v-else></i>
                </td>
              </tr>

              <tr class="taxes">
                <td class="jd-font-regular text-right">SALES TAX</td>
                <td></td>
                <td>${{ cartTotal.tax }}</td>
                <td></td>
                <td></td>
              </tr>
              <tr class="taxes deliver">
                <td class="jd-font-regular text-right">DELIVERY</td>
                <td></td>
                <td>{{ !form.type ? 'Free' : '$35' }}</td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>

          <div class="checkbox--custom">
            <span>Pickup (Free)</span>
            <b-form-checkbox v-model="form.type" name="check-button" switch></b-form-checkbox>
            <span>Delivery ($35)</span>
          </div>

          <div class="d-flex justify-content-end total-box">
            <h2 class="text-right jd-font-bold">Total {{ cartTotal.label }}</h2>
            <button class="btn btn-submit jd-font-bold" @click.prevent="submit">Place order</button>
          </div>
        </section>
      </section>
    </b-container>


    <b-container class="mt-5">
      <section v-html="service.description"></section>
    </b-container>

    <b-container>
      <b-row class="vaults mt-0 mt-lg-5">
        <b-col cols="12" lg="3" v-for="(equipment, key) in service.packs.filter(i => !i.is_big)" class="vault"
               :key="key">
          <div class="d-block d-sm-none flex-grow-1 w-100">
            <h2 class="text-center title jd-font-medium mb-0">{{ equipment.title }}</h2>
            <h3 class="text-center sub-title">{{ equipment.pack }} | <span>${{ equipment.price }}</span></h3>
          </div>

          <section class="d-flex flex-row flex-sm-column">
            <div>
              <div class="d-none d-sm-block">
                <h2 class="title jd-font-medium mb-0">{{ equipment.title }}</h2>
                <h3 class="sub-title">{{ equipment.pack }} | <span>${{ equipment.price }}</span></h3>
              </div>
              <img :src="equipment.main_image_path" class="item-img d-block"/>
            </div>
            <section v-html="equipment.description" class="description mt-0 mt-sm-4 ml-3 ml-sm-0"></section>
          </section>

          <section class="d-flex add-cart--box">
            <button class="btn text-uppercase add-cart-btn"
                    @click="ADD_CART_ITEM({ item: equipment, qt: cartQt[equipment.id] || 1, type: 'pack' })">
              Add to Cart
            </button>
            <div class="form-group inp--box">
              <input type="number"
                     class="form-control add-cart-count-input"
                     max="999"
                     min="1"
                     v-model="cartQt[equipment.id]"
                     @input="(e) => changeQt(e, equipment.id)"
                     placeholder="1"
              />
              <button class="btn add-cart-count-plus-btn"
                      @click="addQt(equipment.id)">
                +
              </button>
            </div>
          </section>
        </b-col>

        <b-col cols="12" lg="6" v-for="(equipment, key) in service.packs.filter(i => i.is_big)" class="vault"
               :key="`big-${key}`">
          <div class="d-block d-sm-none flex-grow-1 w-100">
            <h2 class="text-center title jd-font-medium mb-0">{{ equipment.title }}</h2>
            <h3 class="text-center sub-title">{{ equipment.pack }} | <span>${{ equipment.price }}</span></h3>
          </div>

          <section class="d-flex flex-row flex-sm-column">
            <div>
              <div class="d-none d-sm-block">
                <h2 class="title jd-font-medium mb-0">{{ equipment.title }}</h2>
                <h3 class="sub-title">{{ equipment.pack }} | <span>${{ equipment.price }}</span></h3>
              </div>

              <img :src="equipment.main_image_path" class="item-img d-block big"/>
            </div>

            <section v-html="equipment.description" class="description mt-0 mt-sm-4 ml-3 ml-sm-0"></section>
          </section>

          <section class="d-flex add-cart--box">
            <button class="btn text-uppercase add-cart-btn"
                    @click="ADD_CART_ITEM({ item: equipment, qt: cartQt[equipment.id] || 1, type: 'pack' })">
              Add to Cart
            </button>
            <div class="form-group inp--box">
              <input type="number"
                     class="form-control add-cart-count-input"
                     max="9"
                     placeholder="1"
                     min="1"
                     v-model="cartQt[equipment.id]"
                     @input="(e) => changeQt(e, equipment.id)"
              />
              <button class="btn add-cart-count-plus-btn"
                      @click="addQt(equipment.id)">
                +
              </button>
            </div>
          </section>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row class="vaults mt-0 mt-lg-5">
        <b-col cols="12" lg="3" v-for="(equipment, key) in service.products.filter(i => !i.is_big)" class="vault"
               :key="key">

          <div class="d-flex justify-content-center d-sm-none align-items-center flex-grow-1 w-100 mb-3">
            <h2 class="text-center title jd-font-medium mb-0 mt-0">{{ equipment.title }}</h2>
            <h3 class="text-center sub-title mb-0 ml-2"><span>${{ equipment.price }}</span></h3>
          </div>

          <section class="d-flex flex-row flex-sm-column">
            <div>
              <div class="d-none d-sm-block">
                <h2 class="title jd-font-medium mb-0">{{ equipment.title }}</h2>
                <h3 class="sub-title"><span>${{ equipment.price }}</span></h3>
              </div>

              <img :src="equipment.main_image_path" class="item-img d-block" alt=""/>
              <h3 class="text-center text-sm-left sub-title mt-4 mb-2"> {{ equipment.pack }}</h3>
            </div>

            <section v-html="equipment.description" class="description mt-0 mt-sm-4 ml-3 ml-sm-0"></section>
          </section>

          <section class="d-flex add-cart--box">
            <button class="btn text-uppercase add-cart-btn"
                    @click="ADD_CART_ITEM({ item: equipment, qt: cartQt[equipment.id] || 1, type: 'product' })">
              Add to Cart
            </button>
            <div class="form-group inp--box">
              <input type="number"
                     class="form-control add-cart-count-input"
                     max="9"
                     min="1"
                     v-model="cartQt[equipment.id]"
                     @input="(e) => changeQt(e, equipment.id)"
                     placeholder="1"
              />
              <button class="btn add-cart-count-plus-btn"
                      @click="addQt(equipment.id)">
                +
              </button>
            </div>
          </section>
        </b-col>
      </b-row>
    </b-container>

    <section class="position-relative pt-triangle d-none d-lg-block">
      <b-img src="/img/home/triangle-left.png" class="triangle right triangle--full__width" data-aos="right-left"
             data-aos-duration="1000"></b-img>
      <home-specialize :items="HOME_SPECIALIZES" class="pt-3 mt-5" :inline="true"/>
      <b-img src="/img/home/triangle-right.png" class="triangle triangle--full__width mt-negative" data-aos="left-right"
             data-aos-duration="1000"></b-img>
    </section>

    <section class="position-relative pt-triangle d-block d-lg-none">
      <b-img src="/img/home/triangle-left.png" class="triangle right triangle--full__width" data-aos="right-left"
             data-aos-duration="1000"></b-img>
      <home-specialize :items="HOME_SPECIALIZES" class="pt-3 mt-5" :inline="true"/>
      <b-img src="/img/home/triangle-right.png" class="triangle triangle--full__width mt-negative" data-aos="left-right"
             data-aos-duration="1000"></b-img>
    </section>
  </section>

</template>

<script>
import BannerLeft from "@/views/_partials/Home/BannerLeft";
import BannerRight from "@/views/_partials/Home/BannerRight";
import HomeSpecialize from "@/views/_partials/Home/HomeSpecialize";
import Gallery from "../../components/Gallery";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "SupplyService",
  components: {HomeSpecialize, BannerRight, BannerLeft, Gallery},
  props: ["service"],
  beforeCreate() {
    this.$store.dispatch("GET_HOME_SPECIALIZE_DETAILS");
  },
  computed: {
    ...mapGetters(["HOME_SPECIALIZES", "CART_ITEMS"]),
    cartQt() {
      return this.cart_products_qt;
    },
    cartTotal() {
      let total = 0;
      let tax = 9.38;
      this.CART_ITEMS.forEach((item) => {
        total += (item.price * item.count)
      });

      let taxTotal = total * tax / 100
      total += taxTotal;

      if (this.form.type) {
        total += 35;
      }

      return {
        total: total,
        label: `$${total.toFixed(2)}`,
        tax: taxTotal.toFixed(2)
      };
    }
  },
  mounted() {
    if (this.$route.hash === '#cart') {
      let cart = document.getElementById("cart");
      if (cart) {
        return window.scrollTo(0, cart.offsetTop - 120);
      }
    }
  },
  methods: {
    ...mapActions(['ADD_CART_ITEM', 'SET_CART_COUNT', 'REMOVE_CART_ITEM', 'GET_STRIPE_SESSION_ID', 'REMOVE_CART_ITEMS']),
    addQt(index) {
      if (!this.cart_products_qt[index]) {
        this.cart_products_qt[index] = 2;
      } else {
        if (this.cart_products_qt[index] === 9) return;
        this.cart_products_qt[index]++
      }

      this.$forceUpdate();
    },
    submit() {
      this.$router.push({name: 'Checkout', query: {type: this.form.type ? 1 : 0}});
    },
    changeQt(e, id) {
      if (+e.target.value < 1) {
        e.preventDefault();
        this.cartQt[id] = 1;
        return;
      }

      if (+e.target.value > 999) {
        e.preventDefault();
        this.cartQt[id] = 999;
        return;
      }

      this.cartQt[id] = +e.target.value;
    },
    editCartQt(id, count) {
      this.editId = id;
      this.editQt = count;
    },
    editQtSave(id) {
      this.editId = null;
      this.SET_CART_COUNT({id, qt: this.editQt});
    }
  },
  data() {
    return {
      form: {
        type: false
      },
      editId: null,
      editQt: 0,
      cart_products_qt: {},
      gallerySizes: {
        lg: 4,
        md: 4,
        sm: 6,
        cols: 12
      }
    }
  }
}
</script>

<style scoped lang="scss">
.eq-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  text-transform: uppercase;

  &:after {
    content: "";
    width: 40%;
    left: 0;
    right: 0;
    height: 5px;
    background: #184782;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

.eq-sub-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #184782;
}

.pt-triangle {
  padding-top: 30px;
}

.mt-negative {
  position: relative;
  margin-top: -100px;
  z-index: -1;
  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
}

.triangle--full__width {
  width: 100%;
  height: 200px;
}

.triangle.right {
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 992px) {

}

.vaults {
  @media screen and (max-width: 575px) {
    padding-right: 15px;
  }

  .vault {
    margin-bottom: 64px;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 22px;
      border: 1px solid #000;
      border-radius: 0 42px 42px 0;
      border-left: 0;
      padding: 20px 20px 20px 5px;
    }

    .item-img {
      height: 200px;
      width: 100%;

      &:not(.big) {
        max-width: 200px;
      }

      &.big {
        height: 280px;
      }

      object-fit: contain;

      @media screen and (max-width: 575px) {
        max-width: 200px !important;
        height: 200px !important;
      }

      &.inline {
        margin-bottom: 10px;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e;
      margin-top: 10px;
      @media screen and (max-width: 575px) {
        text-align: right;
        white-space: nowrap;
      }
    }

    .sub-title {
      font-size: 18px;
      color: #000;
      margin-bottom: 20px;
      @media screen and (max-width: 575px) {
        text-align: right;
        white-space: nowrap;
      }

      span {
        color: #fa6400;
      }
    }

    .description {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;

      ::v-deep p {
        margin-bottom: 0;
      }
    }

    .size {
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}

.shopping--cart-box {
  position: relative;
  width: 50%;
  margin: 12px 0;
  padding: 9px 40px 45px;
  border-radius: 20px;
  background-image: url("/img/Shopping_Cart_Wite_Large.svg");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: left 50px;

  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 5px 25px 45px;
    background-position: left 30px;
  }

  .title {
    text-align: center;
    color: #ef4e23;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-transform: uppercase;
  }

  .items {
    table {
      tr, td, th {
        color: #707070;

        :not(:nth-child(1)) {
          color: rgba(0, 0, 0, 0.9);
        }

        :last-child {
          color: #707070;
        }
      }

      th {
        font-weight: 600;
        padding: 2px 6px;
        color: rgba(0, 0, 0, 0.6) !important;
        font-size: 16px;
      }

      td {
        padding: 2px 6px;
      }

      tr.taxes {
        td {
          border-top: 1px solid #707070;
        }
      }

      tr.deliver {
        td {
          border-top: none !important;
          border-bottom: 1px solid #707070;
        }
      }
    }
  }

  .total-box {
    margin-top: 20px;
    flex-direction: column;
    @media screen and (max-width: 420px) {
      margin-top: 85px;
    }

    h2 {
      opacity: 0.8;
      font-size: 27px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: right;
      color: rgba(0, 0, 0, 0.7);
      text-transform: uppercase;
    }

    button {
      align-self: flex-end;
      border-radius: 150px;
      text-transform: uppercase;
      width: 160px;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.8px;
      text-align: center;
      color: #ef4e23;
      background: rgba(239, 78, 35, 0.15);
    }
  }
}

.add-cart--box {
  align-items: center;
  max-width: 200px;
  margin-top: 11px;
  @media screen and (max-width: 575px) {
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
    margin-top: 24px;
    margin-left: auto;
  }

  .add-cart-btn {
    align-self: flex-start;
    flex: 1;
    margin: 0 8px 0 0;
    padding: 4px 0 4px 1px;
    border-radius: 150px;
    background-color: #ef4e23;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.6px;
    text-align: center;
    color: #fff;
    transition: 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }

  .inp--box {
    height: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    border: solid 1px #ef4e23;
    background: #ef4e23;
    border-radius: 4px;
  }

  .add-cart-count-input {
    width: 35px;
    height: 23px;
    border: 0;
    background: #fff;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    color: #4a4a4a;
    line-height: 0;
    padding: 0;

    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &input[type=number] {
      -moz-appearance: textfield; /* Firefox */
    }
  }

  .add-cart-count-plus-btn {
    color: #fff;
    padding: 0 5px;
    margin-top: -2px;
  }
}

.c-pointer {
  cursor: pointer;
}


.checkbox--custom {
  transform: rotate(90deg);
  position: absolute;
  left: 0;
  bottom: 71px;
  z-index: 44;
  @media screen and (max-width: 420px) {
    bottom: 155px;
  }

  &:after {
    content: '';
    width: 125px;
    position: absolute;
    height: 1px;
    background: #e8452a;
    display: block;
    transform: rotate(90deg);
    top: -64px;
    right: -18px;
  }

  > span {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: right;
    color: #8a8a8a;
    display: block;
    transform: rotate(-90deg);

    &:first-child {
      position: absolute;
      top: -75px;
      right: 15px;
      white-space: nowrap;
    }

    &:last-child {
      position: absolute;
      top: -79px;
      right: -30px;
      white-space: nowrap;
    }
  }

  > div {
    margin-right: 8px;
    margin-top: -6px;
    margin-left: 8px;
  }
}

::v-deep .checkbox--custom .custom-control {
  width: 72px;
  height: 30px;

  .custom-control-label::before {
    width: 72px;
    height: 30px;
    border-radius: 150px;
    background: #fff;
    border: solid 2px #ef4e23;
  }

  .custom-control-label::after {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px #ef4e23;
    background-color: #ef4e23;
    top: calc(0.25rem + 3px);
    left: calc(-2.25rem + 3px);
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(41px);
  }
}

::v-deep .checkbox--custom .form-control {
  height: 35px;
  padding-top: 3px;
}


.input-edit {
  border-radius: 4px;
  border: 1px solid #707070;
  width: 48px;
  text-align: center;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
