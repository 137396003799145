<template>
  <div>
    <!--Slider Section Start-->
    <slider :without-hero="true" :images="images" v-if="images.length" classes="service--slider"
            :show-call="true"
            :sliderPageTitle="service.hero_title"
    ></slider>
    <!--Slider Section End-->

    <template v-if="service.table === 'App\\Models\\Storage'">
      <StorageService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\Residental'">
      <ResidentialService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\Commercial'">
      <CommercialService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\ECrates'">
      <ECratesService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\Supply'">
      <SupplyService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\LongDistance'">
      <LongDistanceService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\JunkRemoval'">
      <JunkRemovalService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\PackingUnpacking'">
      <PackingUnpackingService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\PianoMoving'">
      <PianoMovingService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\WhiteGlove'">
      <WhiteGloveService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\OfficeMoving'">
      <OfficeMovingService :service="service"/>
    </template>
    <template v-else-if="service.table === 'App\\Models\\OfficeFurniture'">
      <OfficeFurnitureService :service="service"/>
    </template>

    <template v-if="!['App\\Models\\LongDistance', 'App\\Models\\Residental'].includes(service.table)">
      <testimonials class="mt-5" />
      <jd-video/>
    </template>
  </div>
</template>

<script>
import Slider from "./_partials/Home/Slider";
import Specialize from "./_partials/Home/Specialize";
import JdVideo from "./_partials/Home/Video";
import Gallery from "../components/Gallery";
import Services from "./_partials/Home/Services";
import ServiceTestimonials from "./_partials/Service/Testimonials"
import Testimonials from "./_partials/Home/Testimonials"
import {mapGetters} from "vuex";
import {RepositoryFactory} from "@/api/RepositoryFactory"
import StorageService from "@/views/Services/Storage";
import ResidentialService from "@/views/Services/Residential";
import CommercialService from "@/views/Services/Commercial";
import ECratesService from "@/views/Services/ECrates";
import SupplyService from "@/views/Services/SupplyService";
import LongDistanceService from "@/views/Services/LongDistance";
import JunkRemovalService from "@/views/Services/JunkRemoval";
import PackingUnpackingService from "@/views/Services/PackingUnpacking";
import PianoMovingService from "@/views/Services/PianoMoving";
import WhiteGloveService from "@/views/Services/WhiteGlove";
import OfficeMovingService from "@/views/Services/OfficeMoving.vue";
import OfficeFurnitureService from "@/views/Services/OfficeFurniture.vue";

export default {
  components: {
    OfficeFurnitureService,
    OfficeMovingService,
    PianoMovingService,
    LongDistanceService,
    SupplyService,
    ECratesService,
    CommercialService,
    ResidentialService,
    JunkRemovalService,
    PackingUnpackingService,
    WhiteGloveService,
    StorageService, Slider, Specialize, JdVideo, Gallery, Services, ServiceTestimonials, Testimonials
  },
  data() {
    return {
      slug: this.$route.params.slug,
      service: {},
      specialize: []
    }
  },
  metaInfo() {
    return {
      title: this.service.meta_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.service.meta_description
        },
        {
          vmid: "title",
          name: "title",
          content: this.service.meta_title
        }
      ]
    };
  },
  beforeCreate() {
    this.$store.dispatch("GET_SERVICES_NAMES");
    this.$store.dispatch("GET_SLIDERS");
    this.$store.dispatch("GET_SPECIALIZE_DETAILS");
    this.$store.dispatch("GET_HOME_PAGE_INFO");
  },
  created() {
    RepositoryFactory.get('service').show(this.slug).then(({data: {service}}) => {
      this.service = service;
      this.$root.$emit('hideLoader');
    });
  },
  computed: {
    ...mapGetters(['SERVICES', 'SLIDERS', 'SPECIALIZES']),
    FILTERED_SERVICES: function () {
      return this.SERVICES.filter(item => item.slug !== this.slug);
    },
    images: function () {
      return this.service && this.service.sliders && this.service.sliders.map(item => item.path) || [];
    }
  },
  watch: {
    'SPECIALIZES': function (newVal) {
      this.specialize = this.chunk(newVal, Math.ceil(newVal.length / 3));
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 992px) {
  .service--slider {
    height: 45vh !important;

    .trinagle-bottom {
      height: 50px !important;
    }
  }

  .mt-mobile-0 {
    margin-top: 10px !important;
  }
}
</style>

<style scoped lang="scss">
.triangle {
  position: absolute;
  left: 0;
  top: 100px;
  z-index: 0;
  width: 100%;
  height: 400px;
}

.triangle--full__width {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 400px;
}

.z-index-1 {
  z-index: 1;
}

@media screen and (min-width: 992px) {
  .px-lg-100 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
}
</style>
