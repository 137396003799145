<template>
  <section>
    <b-container class="my-3">
      <section v-html="service.description"></section>
    </b-container>

    <section class="headline-style d-none d-lg-block">
      <section class="headline jd-text-dark py-2">
        <b-container>
          <p class="jd-text-29 m-0 jd-text-20__mobile jd-font-regular jd-home-text text-letter text-wrap-pre text-uppercase text-center" >
            {{ service.what_we_can_do_for_you_title }}
          </p>
        </b-container>
      </section>
    </section>

    <b-container>
      <section v-html="service.what_we_can_do_for_you"></section>
    </b-container>

    <banner-right :image="service.banner_first_path"
                  :text="service.banner_first_text"
                  width
                  class="d-none d-lg-block mt-negative z-index--1"></banner-right>

    <banner-right :image="service.banner_first_path"
                  :text="service.banner_first_text"
                  width
                  class="d-block d-lg-none mt-negative" only-image></banner-right>

    <home-specialize :items="HOME_SPECIALIZES" class="pt-3 mb-5 mt-negative" :inline="true" />

    <banner-left :image="service.banner_second_path"
                 :only-image="true"
                 width
                 class="mb-5"></banner-left>

    <b-container class="mb-3">
      <section class="text-center" v-html="service.second_description"></section>
    </b-container>


    <b-container>
      <b-row class="vaults">
        <b-col cols="12" lg="6" v-for="(blog, key) in service.blogs" class="vault" :key="key">
          <h2 class="title jd-font-medium">{{ blog.title }}</h2>
          <img :src="blog.main_image_path" class="item-img d-block"/>
          <section v-html="blog.description" class="description"></section>
        </b-col>
      </b-row>
    </b-container>
  </section>

</template>

<script>
import BannerLeft from "@/views/_partials/Home/BannerLeft";
import BannerRight from "@/views/_partials/Home/BannerRight";
import HomeSpecialize from "@/views/_partials/Home/HomeSpecialize";
import JdVideo from "./../_partials/Home/Video";
import Testimonials from "./../_partials/Home/Testimonials";

import {mapGetters} from "vuex";
export default {
  name: "WhiteGloveService",
  components: {HomeSpecialize, BannerRight, BannerLeft, JdVideo, Testimonials},
  props: ["service"],
  beforeCreate() {
    this.$store.dispatch("GET_HOME_SPECIALIZE_DETAILS");
  },
  computed: {
    ...mapGetters(["HOME_SPECIALIZES"]),
  }
}
</script>

<style scoped lang="scss">
.z-index--1 {
  z-index: -1 !important;
}
.mt-negative {
  margin-top: -120px;
  margin-bottom: -15px !important;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
}

.vaults {
  .vault {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 15px;
      top: 0;
      right: 0;
      height: 100px;
      opacity: 0.05;
      -webkit-filter: blur(10px);
      filter: blur(10px);
      background-color: #000000;
      z-index: -1;
      width: calc(100% - 30px);
    }

    margin-bottom: 64px;
    .item-img {
      height: 200px;
      width: 100%;
      object-fit: cover;

      &.inline {
        margin-bottom: 10px;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e;
      margin-top: 10px;
    }

    .description {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
    }

    .size {
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}
</style>
