<template>
  <section>
    <b-container>
      <section v-html="service.description"></section>
    </b-container>

    <section class="headline-style d-none d-lg-block">
      <section class="headline jd-text-dark py-2">
        <b-container>
          <p class="jd-text-29 m-0 jd-text-20__mobile jd-font-regular jd-home-text text-letter text-wrap-pre text-uppercase text-center" >
            {{ service.what_we_can_do_for_you_title }}
          </p>
        </b-container>
      </section>
    </section>

    <b-container class="mt-3 d-none d-lg-block">
      <section v-html="service.what_we_can_do_for_you"></section>
    </b-container>

    <banner-right :image="service.banner_first_path"
                  :text="service.banner_first_text"
                  width
                  only-image
                  class="d-block d-lg-none"></banner-right>

    <home-specialize :items="HOME_SPECIALIZES" class="pt-3 mb-5 mt-negative d-block d-lg-none" :inline="true" />

    <b-container class="e-crates-banner mt-5 mt-lg-0">
      <b-row>
        <b-col cols="12" lg="6">
          <img src="/img/e_c_03.webp" alt="" width="100%" data-aos="left-right" data-aos-offset="20"/>
        </b-col>
        <b-col cols="12" lg="6" class="texts">
          <p class="jd-font-bold" data-aos="right-left" data-aos-offset="20" data-aos-delay="50">Eco friendly</p>
          <p class="jd-font-ultra-light" data-aos="right-left" data-aos-offset="20">Cost-efficient</p>
          <p class="jd-font-bold" data-aos="right-left" data-aos-offset="20" data-aos-delay="100">Designed for</p>
          <p class="jd-font-medium" data-aos="right-left" data-aos-offset="20" data-aos-delay="200">office moving</p>

          <a href="" class="jd-font-bold" data-aos="right-left" data-aos-offset="20">
            Read more
          </a>
        </b-col>
      </b-row>
    </b-container>
    <banner-right :image="service.banner_first_path"
                  :text="service.banner_first_text"
                  width
                  class="d-none d-lg-block"></banner-right>

    <home-specialize :items="HOME_SPECIALIZES" class="pt-3 mb-5 mt-negative d-none d-lg-block" :inline="true" />

    <banner-left :image="service.banner_second_path"
                 :only-image="true"
                 width
                 class="mb-5 d-none d-lg-block"></banner-left>


    <b-container>
      <h2 class="jd-text-24 eq-title jd-font-bold">{{ service.equipments_title }}</h2>
      <h6 class="eq-sub-title jd-font-bold d-none d-lg-block" v-html="service.equipments_sub_title"></h6>
      <b-row class="vaults mt-5">
        <b-col cols="12" lg="6" v-for="(equipment, key) in service.equipments" class="vault" :key="key">
          <h2 class="title jd-font-medium mb-3">{{ equipment.title }}</h2>
          <img :src="equipment.main_image_path" class="item-img d-block"/>
          <section v-html="equipment.description" class="description"></section>
        </b-col>
      </b-row>

      <img src="/img/customers.png" width="100%" />
    </b-container>
  </section>

</template>

<script>
import BannerLeft from "@/views/_partials/Home/BannerLeft";
import BannerRight from "@/views/_partials/Home/BannerRight";
import HomeSpecialize from "@/views/_partials/Home/HomeSpecialize";
import {mapGetters} from "vuex";
export default {
  name: "CommercialService",
  components: {HomeSpecialize, BannerRight, BannerLeft},
  props: ["service"],
  beforeCreate() {
    this.$store.dispatch("GET_HOME_SPECIALIZE_DETAILS");
  },
  computed: {
    ...mapGetters(["HOME_SPECIALIZES"]),
  }
}
</script>

<style scoped lang="scss">
.eq-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  text-transform: uppercase;
  &:after {
    content: "";
    width: 40%;
    left: 0;
    right: 0;
    height: 5px;
    background: #184782;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

.eq-sub-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #184782;
}

.mt-negative {
  margin-top: -120px;
  margin-bottom: -15px !important;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
}


.e-crates-banner {
  margin-bottom: -100px;
  z-index: 5;
  position: relative;
  @media screen and (max-width:992px){
    margin-bottom: 32px;
  }
  .texts {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 35px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: normal;
    color: #4a4a4a;
    text-transform: uppercase;
    p {
      margin-bottom: 3px;
    }

    p:nth-child(2) {
      margin-left: 20%;
    }

    p:nth-child(3) {
      margin-left: 10%;
      color: #0fb584;
    }

    p:nth-child(4) {
      margin-left: 10%;
      color: #036f4b;
    }

    a {
      font-size: 24px;
      margin-top: 30px;
      color: #4a4a4a;
    }
  }
}
.vaults {
  .vault {
    margin-bottom: 64px;
    .item-img {
      height: 200px;
      width: 100%;
      object-fit: contain;

      &.inline {
        margin-bottom: 10px;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e;
      margin-top: 10px;
    }

    .description {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
    }

    .size {
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}
</style>
