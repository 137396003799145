<template>
  <section>
    <b-container>
      <section v-html="service.description"></section>
    </b-container>

    <banner-right :image="service.banner_first_path"
                  :text="service.banner_first_text"
                  width
                  class="d-none d-lg-block"></banner-right>

    <home-specialize :items="HOME_SPECIALIZES" class="pt-3 mb-5 mt-negative" :inline="true" />

    <banner-left :image="service.banner_second_path"
                  :only-image="true"
                  width
                  class="mb-5 d-none d-lg-block"></banner-left>


    <b-container>
      <b-row class="vaults mt-5 mt-lg-0">
          <b-col cols="12" lg="4" v-for="(vault, key) in service.vaults" class="vault d-flex flex-column justify-content-center" :key="key">
            <img :src="vault.main_image_path" class="item-img d-block"/>
            <h2 class="title">{{ vault.title }}</h2>
            <section v-html="vault.description" class="description"></section>
            <p class="size">{{ vault.size }}</p>
          </b-col>
      </b-row>
    </b-container>
  </section>

</template>

<script>
import BannerLeft from "@/views/_partials/Home/BannerLeft";
import BannerRight from "@/views/_partials/Home/BannerRight";
import HomeSpecialize from "@/views/_partials/Home/HomeSpecialize";
import {mapGetters} from "vuex";
export default {
  name: "StorageService",
  components: {HomeSpecialize, BannerRight, BannerLeft},
  props: ["service"],
  beforeCreate() {
    this.$store.dispatch("GET_HOME_SPECIALIZE_DETAILS");
  },
  computed: {
    ...mapGetters(["HOME_SPECIALIZES"]),
  }
}
</script>

<style scoped lang="scss">
.mt-negative {
  margin-top: -120px;
  margin-bottom: -15px !important;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 992px) {
  .mt-negative {
    margin-top: 0;
    margin-bottom: 0 !important;
  }
}
.vaults {
  .vault {
    margin-bottom: 64px;
    .item-img {
      -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
      transition: 0.3s ease;
      height: 150px;
      object-fit: contain;
      &:hover {
        -webkit-filter: grayscale(0);
        filter: none;
      }

      &.inline {
        margin-bottom: 10px;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e;
      margin-top: 10px;
    }

    .description {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
    }

    .size {
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}
</style>