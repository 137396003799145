<template>
  <section>
    <section class="headline-style d-block">
      <section class="headline jd-text-dark py-2">
        <b-container>
          <p class="jd-text-29 m-0 jd-text-20__mobile jd-font-regular jd-home-text text-letter text-wrap-pre text-uppercase text-center" >
            {{ service.title }}
          </p>
        </b-container>
      </section>
    </section>

    <b-container class="mt-5">
      <section v-html="service.description"></section>
    </b-container>

    <section class="headline-style d-block">
      <section class="headline jd-text-dark py-2">
        <b-container>
          <p class="jd-text-29 m-0 jd-text-20__mobile jd-font-regular jd-home-text text-letter text-wrap-pre text-uppercase text-center" >
            {{ service.second_title }}
          </p>
        </b-container>
      </section>
    </section>

    <b-container class="mt-5">
      <section v-html="service.second_description"></section>
    </b-container>

    <section class="position-relative pt-triangle d-none d-lg-block">
      <b-img src="/img/home/triangle-left.png" class="triangle right triangle--full__width" data-aos="right-left" data-aos-duration="1000"></b-img>
      <home-specialize :items="HOME_SPECIALIZES" class="pt-3 mt-5" :inline="true" />
      <b-img src="/img/home/triangle-right.png" class="triangle triangle--full__width mt-negative" data-aos="left-right" data-aos-duration="1000"></b-img>
    </section>

    <b-container>
      <h2 class="jd-text-24 eq-title jd-font-bold">{{ service.kits_title }}</h2>
      <h6 class="eq-sub-title jd-font-bold" v-html="service.kits_sub_title"></h6>
      <b-row class="vaults mt-5">
        <b-col cols="12" lg="4" v-for="(equipment, key) in service.kits.filter(i => !i.is_big)" class="vault" :key="key">
          <h2 class="title jd-font-medium mb-3">{{ equipment.pack }} {{ equipment.title }}</h2>
          <h3 class="sub-title"><span>{{ equipment.price }}</span> | {{ equipment.time }}</h3>
          <img :src="equipment.main_image_path" class="item-img d-block"/>
          <section v-html="equipment.description" class="description"></section>
        </b-col>

        <b-col cols="12" lg="6" v-for="(equipment, key) in service.kits.filter(i => i.is_big)" class="vault" :key="`big-${key}`">
          <h2 class="title jd-font-medium mb-0">{{ equipment.pack }} {{ equipment.title }}</h2>
          <h3 class="sub-title"><span>{{ equipment.price }}</span> | {{ equipment.time }}</h3>
          <img :src="equipment.main_image_path" class="item-img d-block"/>
          <section v-html="equipment.description" class="description"></section>
        </b-col>
      </b-row>
    </b-container>

    <section class="position-relative pt-triangle d-block d-lg-none">
      <b-img src="/img/home/triangle-left.png" class="triangle right triangle--full__width" data-aos="right-left" data-aos-duration="1000"></b-img>
      <home-specialize :items="HOME_SPECIALIZES" class="pt-3 mt-5" :inline="true" />
      <b-img src="/img/home/triangle-right.png" class="triangle triangle--full__width mt-negative" data-aos="left-right" data-aos-duration="1000"></b-img>
    </section>


    <!--Gallery Section Start-->
    <gallery :images="service.galleries" :sizes="gallerySizes" class="d-none d-lg-block"></gallery>
    <!--Gallery Section End-->
  </section>

</template>

<script>
import BannerLeft from "@/views/_partials/Home/BannerLeft";
import BannerRight from "@/views/_partials/Home/BannerRight";
import HomeSpecialize from "@/views/_partials/Home/HomeSpecialize";
import Gallery from "../../components/Gallery";
import {mapGetters} from "vuex";
export default {
  name: "ECratesService",
  components: {HomeSpecialize, BannerRight, BannerLeft, Gallery},
  props: ["service"],
  beforeCreate() {
    this.$store.dispatch("GET_HOME_SPECIALIZE_DETAILS");
  },
  computed: {
    ...mapGetters(["HOME_SPECIALIZES"]),
  },
  data() {
    return {
      gallerySizes: {
        lg: 4,
        md: 4,
        sm: 6,
        cols: 12
      }
    }
  }
}
</script>

<style scoped lang="scss">
.eq-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  text-transform: uppercase;
  &:after {
    content: "";
    width: 40%;
    left: 0;
    right: 0;
    height: 5px;
    background: #184782;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

.eq-sub-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #184782;
}

.pt-triangle {
  padding-top: 30px;
}
.mt-negative {
  position: relative;
  margin-top: -100px;
  z-index: -1;
  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
}
.triangle--full__width {
  width: 100%;
  height: 200px;
}

.triangle.right {
  position: absolute;
  right:0;
  top: 0;
}
@media screen and (max-width: 992px) {

}
.vaults {
  .vault {
    margin-bottom: 64px;
    .item-img {
      height: 200px;
      width: 100%;
      object-fit: contain;

      &.inline {
        margin-bottom: 10px;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #8e8e8e;
      margin-top: 10px;
    }

    .sub-title {
      font-size: 18px;
      color: #000;
      margin-bottom: 20px;
      span {
        color: #fa6400;
      }
    }

    .description {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
    }

    .size {
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #4a4a4a;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}
</style>